<template>
  <b-card
    class="col-12"
    title="Users">
     <input type="search" placeholder="Search responses..." @keyup="filterUsers" v-model="searchValue">   
    <br>
    <br>
    <b-button variant="success" v-b-modal.modal-project class="my-4">Add user</b-button>

    <b-table :items="filteredUsers" :fields="fields">
      <template #cell(projects)="data">
        <div v-if="data.item.customClaims">
          <span v-for="value in data.item.customClaims.projects" v-bind:key="value.id">{{value.name}}, </span>
        </div>
      </template>
      <template #cell(delete)="data">
        <div class="status-icon inline-icon" @click="loadUser(data.item)" v-b-modal.modal-update>
          <b-icon icon="pencil"></b-icon>
        </div>
        <div class="status-icon inline-icon" @click="deleteUser(data.item)">
          <b-icon icon="trash"></b-icon>
        </div>
      </template>
    </b-table>

    <b-modal id="modal-project" title="Add new user" @ok="saveUser">
      <p class="error-text" v-if="!formValid">All fields are required</p>
      <b-form-input v-model="userModel.name" placeholder="Name" class="mb-3"></b-form-input>
      <b-form-input v-model="userModel.email" placeholder="Email" class="mb-3"></b-form-input>
      <b-form-input v-model="userModel.password" placeholder="Password" class="mb-3"></b-form-input>
      <!--<b-form-input v-model="userModel.company" placeholder="Company name" class="mb-3"></b-form-input>-->
      <b-form-select v-model="userModel.company" :options="companies" placeholder="Company name"></b-form-select>
      <br>
      <br>
      <b-form-input v-model="userModel.phone" placeholder="Phone number" class="mb-3"></b-form-input>
      <!--<b-form-select v-model="userModel.projects" :options="projects" multiple :select-size="4"></b-form-select>-->
    </b-modal>

    <b-modal id="modal-update" title="Update user" @ok="updateUser">
      <p class="error-text" v-if="!updateValid">All fields are required</p>
      <b-form-input v-model="updateModel.name" placeholder="Name" class="mb-3"></b-form-input>
      <b-form-input v-model="updateModel.email" placeholder="Email" class="mb-3"></b-form-input>
      <!--<b-form-input v-model="updateModel.company" placeholder="Company name" class="mb-3"></b-form-input>-->
      <b-form-select v-model="updateModel.company" :options="companies" placeholder="Company name"></b-form-select>
      <br>
      <br>
      <b-form-input v-model="updateModel.phone" placeholder="Phone number" class="mb-3"></b-form-input>
      <!--<b-form-select v-model="updateModel.projects" :options="projects" multiple :select-size="4"></b-form-select>-->
    </b-modal>
  </b-card>
</template>

<script>
export default {
  data: function () {
    return {
      companies: [],
      filteredUsers: [],
      userModel: {
        company: '',
        name: '',
        email: '',
        role: 'USER',
        phone: '',
        projects: []
      },
      updateModel: {
        id: '',
        company: '',
        name: '',
        email: '',
        role: 'USER',
        phone: '',
        projects: []
      },
      users: [],
      projects: [],
      filteredprojects:[],
      formValid: true,
      updateValid: true,
      fields: [
        {
          key: 'displayName',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'customClaims.company',
          label: 'Company'
        },
        /*{
          key: 'projects',
          label: 'Projects'
        },*/
        {
          key: 'delete',
          label: 'Actions'
        },
      ],
      searchValue : ''
    }
  },

  mounted () {
    this.loadUsers()
    this.loadProjects()
  },

  methods: {
    
     filterUsers() {
      this.filteredUsers = this.users.filter((item) => {
        let projectFound = false,
            query = this.searchValue.toLowerCase();

        item.customClaims.projects.forEach((item2) => {
          if (item2.name.toLowerCase().indexOf(query) !== -1) {
            projectFound = true
          }
        });

        return item.displayName.toLowerCase().indexOf(query) !== -1 || projectFound
      })
    },

    async loadUsers () {
      try {
        let resp = await this.$axios.get('/users')
        this.users = resp.data.users
        this.filteredUsers = resp.data.users
        console.log(this.users)
      } catch (e) {
        console.log(e)
      }
    },

    async loadProjects () {
      try {
        let resp = await this.$firestore.collection('projects').get()
        let projectsResponse = this.getSnapshotRecords(resp, false)
        let companies = []

        projectsResponse.forEach((item) => {
          if (item.companies) {
            let comp = item.companies.split(',')

            comp.forEach((i) => {
              let found = false

              companies.forEach((ii) => {
                if (i == ii.value) {
                  found = true
                }
              })

              if (!found) {
                companies.push({
                  value: i,
                  text: i
                })
              }
            })
          }
        })

        this.companies = companies

        this.projects = projectsResponse.map((item) => {
          return {
            value: item.id,
            text: item.name
          }
        })
         this.filteredprojects = projectsResponse.map((item) => {
          return {
            value: item.id,
            text: item.name
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async deleteUser (data) {
      try {
        await this.$axios.delete('/users/' + data.uid)

        this.loadUsers()
      } catch (e) {
        console.log(e)
      }
    },

    validateUser () {
      this.formValid = true
      for (let index in this.userModel) {
        if (!this.userModel[index].length && index != 'projects') {
          this.formValid = false
          return false
        }
      }

      return true
    },

    validateUpdate () {
      this.updateValid = true
      for (let index in this.updateModel) {
        if (!this.updateModel[index].length && index != 'projects') {
          this.updateValid = false
          return false
        }
      }

      return true
    },

    projectName (id) {
      let returnName = ''

      this.projects.forEach((item) => {
        if (item.value == id) {
          returnName = item.text
        }
      })

      return returnName
    },

    async saveUser (e) {
      try {
        if (!this.validateUser()) {
          e.preventDefault()
          return false
        }

        await this.$axios.post('/users', {
          name: this.userModel.name,
          company: this.userModel.company,
          phone: this.userModel.phone,
          email: this.userModel.email,
          password: this.userModel.password,
          projects: this.userModel.projects.map((item) => {
            return {
              id: item,
              name: this.projectName(item)
            }
          })
        })

        this.loadUsers()
      } catch (e) {
        console.log(e)
      }
    },

    async updateUser (e) {
      try {
        if (!this.validateUpdate()) {
          e.preventDefault()
          return false
        }

        await this.$axios.patch('/users', {
          id: this.updateModel.id,
          name: this.updateModel.name,
          company: this.updateModel.company,
          phone: this.updateModel.phone,
          email: this.updateModel.email,
          projects: this.updateModel.projects.map((item) => {
            return {
              id: item,
              name: this.projectName(item)
            }
          })
        })

        this.loadUsers()
      } catch (e) {
        console.log(e)
      }
    },

    loadUser (data) {
      console.log(data.customClaims)
      this.updateModel.phone = data.customClaims.phone
      this.updateModel.id = data.uid
      this.updateModel.company = data.customClaims.company
      this.updateModel.email = data.email
      this.updateModel.name = data.displayName
      this.updateModel.projects = data.customClaims.projects.map((item) => {
        return item.id
      })
    }
  }
}
</script>

<style>
.error-text {
  color: red;
  text-align: center;
}
</style>
